<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }" style="font-size: 20px;">收集配置列表</el-breadcrumb-item>
  </el-breadcrumb>
  
  <!-- 搜索区域 -->
  <div class="search">
    <span>网址：</span>
    <el-input
      v-model="search"
      style="width: 340px"
      placeholder="输入采集网址搜索列表"
      @keydown="isEnter"
      clearable
    />
    <span>采集类型：</span>
    <el-select
      v-model="type"
      clearable
      placeholder="请选择采集类型"
      size="large"
      style="width: 180px"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <el-button size="mini" type="primary" @click="loadTable">查询</el-button>
    <el-button size="mini" type="success" @click="addNew">新增</el-button>
  </div>
  <!-- 表格区域 -->
  <el-table
    v-loading="loading"
    size="mini"
    :data="tableData"
    style="width: 99%"
  >
    <el-table-column prop="id" label="编号" width="100" />
    <el-table-column prop="create_time" label="创建时间" width="180" />
    <el-table-column prop="type_show" label="采集类型" width="100" />
    <!-- <el-table-column prop="url" label="配置采集链接" width="400" /> -->
    <el-table-column prop="url" label="配置采集链接" width="400">
      <template v-slot="scope">
        <p class="url_p" @click.stop="$toUrl(scope.row.url)">
          {{ scope.row.url }}
        </p>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button
          size="mini"
          type="danger"
          @click.stop="handleDelete(scope.row.id)"
          >删除</el-button
        >
        <el-button size="mini" type="success" @click.stop="edit(scope.row)"
          >编辑</el-button
        >
        <el-button size="mini" type="success" @click.stop="addQuery(scope.row.id)"
        >立即执行采集队列</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="step"
      :total="total"
      v-model:current-page="page"
    >
    </el-pagination>
  </div>
  <el-dialog
    v-model="visible"
    :title="title"
    :before-close="handleClose"
    width="700px"
  >
    <el-form :model="form">
      <el-form-item
        v-if="title == '采集配置编辑'"
        label="编号"
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="form.id"
          autocomplete="off"
          placeholder="请输入编号"
          autosize
          :disabled="idShow"
        />
      </el-form-item>
      <el-form-item label="网站地址" :label-width="formLabelWidth">
        <el-input
          v-model="form.url"
          autocomplete="off"
          placeholder="请输入采集地址"
          autosize
        />
      </el-form-item>
      <el-form-item label="采集类型" :label-width="formLabelWidth">
        <el-select v-model="form.type" placeholder="请选择采集类型">
          <el-option label="详情链接" value="详情链接" />
          <el-option label="列表链接" value="列表链接" />
        </el-select>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth">
        目前支持采集的域名：
        <br>
        <el-tag type="info">
          <span>www.hudson.org</span>
        </el-tag>
        <br>
        <el-tag type="info">
          <span>www.hrw.org</span>
        </el-tag>
        <br>

        <el-tag type="info">
          <span>www.rand.org</span>
        </el-tag>
        <br>

        <el-tag type="info">
          <span>www.atlanticcouncil.org</span>
        </el-tag>
        <br>

        <el-tag type="info">
          <span>www.wilsoncenter.org</span>
        </el-tag>
        <br>

        <el-tag type="info">
          <span>jamestown.org</span>
        </el-tag>
        <br>

        <el-tag type="info">
          <span>www.fordfoundation.org</span>
        </el-tag>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="conform"> 提交 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
  <script>
import { reactive, toRefs, watch, onMounted } from "vue";
import { $confirm, $msg_s, $msg_e, $toUrl } from "../../utils/msg";
// 导入角色的API方法
import {configList, configListDel, configListAdd, toAddQuery} from "../../api/webList";
export default {
  name: "list",
  methods: {toAddQuery},
  components: {},
  setup() {
    //定义数据
    let data = reactive({
      //表格数据
      tableData: [],
      //当前页码
      page: 1,
      //每页数量
      step: 20,
      //搜索内容
      search: "",
      //总数量
      total: 0,
      loading: true,
      visible: false,
      formLabelWidth: "100px",
      form: {
        id: "",
        url: "",
        type: "列表链接",
      },
      options: [
        {
          value: "详情链接",
          label: "详情链接",
        },
        {
          value: "列表链接",
          label: "列表链接",
        },
      ],
      type: "",
      title: "采集配置新增",
      idShow: true,
    });
    // 在setup函数内部
    onMounted(() => {
      //执行加载表格数据的方法
      loadTable();
    });
    //加载表格数据的方法
    let loadTable = async () => {
      data.loading = true;
      let type = 0;
      if (data.type == "详情链接") {
        type = 1;
      } else if (data.type == "列表链接") {
        type = 2;
      } else {
        type = 0;
      }
      //查询条件
      let params = {
        page: data.page,
        step: data.step,
        search: data.search,
        type: type,
      };
      //获取查询结果
      let r = await configList(params);
      console.log(r);
      data.total = r.data.count;
      data.tableData = r.data.data;
      data.loading = false;
    };
    // 监听当前页码是否发生变化
    watch(
      () => data.page,
      () => {
        //重新加载表格数据
        loadTable();
      }
    );
    //删除方法
    let handleDelete = async (id) => {
      const res = await $confirm("确定要删除吗？");
      console.log(res);
      if (res) {
        console.log(id);
        let params = {
          ids: id,
        };
        let r = await configListDel(params);
        console.log(r);
        if (r.code == 200) {
          $msg_s(r.message);
        } else {
          $msg_e(r.message);
        }
        loadTable();
      }
    };
    //执行采集
    let addQuery = async (id) => {
      $confirm("立即执行该条采集队列？")
          .then( async () => {
            let params = {
              id: id,
            };
            let res = await toAddQuery(params);
            if (res.code == 200) {
              $msg_s(res.message);
            } else {
              $msg_e(res.message);
            }
            loadTable();
          })
          .catch(() => {
            // catch error
      });
    };
    const handleClose = () => {
      $confirm("你确定要关闭弹出框吗？")
        .then(() => {
          data.form.query_url = "";
          data.visible = false;
        })
        .catch(() => {
          // catch error
        });
    };
    //提交新增或者编辑
    let conform = async () => {
      console.log(data.form);
      let type2 = 0;
      if (data.form.type == "列表链接") {
        type2 = 2;
      } else {
        type2 = 1;
      }
      let params = {
        id: data.form.id,
        url: data.form.url,
        type: type2,
      };
      const res = await configListAdd(params);
      if (res.code == 200) {
        data.form = {
          id: "",
          url: "",
          type: "列表链接",
        };
        data.visible = false;
        $msg_s(res.message);
        //重新加载表格数据
        loadTable();
      } else {
        data.form = {
          id: "",
          url: "",
          type: "列表链接",
        };
        data.visible = false;
        $msg_e(res.message);
        //重新加载表格数据
        loadTable();
      }
    };
    let cancel = () => {
      data.form = {
        id: "",
        url: "",
        type: "列表链接",
      };
      data.visible = false;
    };

    //编辑
    let edit = (content) => {
      console.log(content);
      data.visible = true;
      data.title = "采集配置编辑";
      data.form.url = content.url;
      data.form.id = content.id;
      data.form.type = content.type_show;
    };

    //输入网址键盘事件
    let isEnter = (event) => {
      console.log(event.keyCode);
      if (event.keyCode == 13) {
        //执行加载表格数据的方法
        loadTable();
      }
    };
    let addNew = () => {
      data.title = "采集配置新增";
      data.visible = true;
    };
    return {
      ...toRefs(data),
      handleDelete,
      loadTable,
      handleClose,
      conform,
      cancel,
      edit,
      isEnter,
      addNew,
      $toUrl,
      addQuery
    };
  },
};
</script>
  <style scoped lang="scss">
	  .search{
		  margin-top: 1%;
	  }
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar-uploader-icon svg {
  margin-top: 74px; /* (178px - 28px) / 2 - 1px */
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
.url_p{
  cursor: pointer;
  &:hover{
    color: #409eff;
  }
}
</style>
  